html,
body,
div#root,
div#root>div {
    /* height: 100%; */
}

body {
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
}

body>iframe {
    display: none;
}

div#root {
    background: linear-gradient(296.15deg, #ADD8E6 -12.16%, rgba(214, 207, 255, 0.53) 61.81%, rgba(10, 227, 225, 0) 101.19%);
}

main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.display2lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43.95px;
}

.table-button {
    min-width: unset;
}

.data-management-main-height {
    flex: 1;
    height: 100%;
    width: 100%;
    position: relative;
}

.text-overflow {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

/* @media only screen and (max-width: 600px) {
} */