.lucky-main{
}
.lucky-app {
    margin-top: 60px;
    padding: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
.loader-wrapper {
    position: fixed;
    background-color: #f1f1f1;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.loader-container {
    position: relative;
    margin: 15% auto;
    height: 25vh;
    width: 30vw;
    background-color: white;
}

.tab-switcher {
    display: flex;
    margin: 10px;
    border-radius: 25px;
    width: 35vw;
    padding: 1px;
    max-width: 200px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
}

.tab-switcher .active-tab {
    background-color: #183693;
    color: white;
    border-radius: 25px;
    padding: 5px 10px;
}

.tab-switcher .tab {
    background-color: #ffffff;
    color: black;
    border-radius: 25px;
    padding: 5px 10px;
}

.tab-switcher .tab,
.active-tab:hover {
    cursor: pointer;
}